import styled from "styled-components";
import { HBox } from "../../styles/HBox";
import Section from "../../components/Section";
import Banner from "../../components/Banner";
import { VBox } from "../../styles/VBox";
import Community from "../HomePage/Community";
import { H1 } from "../../styles/Text";

const StyledVBox = styled(VBox)`
  padding: 40px;
  gap: 2rem;
  p {
    @media screen and (max-width: ${({ theme }) => `${theme.tablet}`}) {
      font-size: 1.8rem;
    }
    max-width: 900px;
    b {
      font-weight: 600;
    }
  }
`;

const AboutUs = () => {
  return (
    <Section head="About Us">
      <VBox style={{ gap: "2rem", marginBottom: "-20px" }}>
        <VBox>
          <Banner
            src="/skyline.jpg"
            heading="Who are we ?"
            subHeading="Allow us to tell a little bit about ourselves"
            anim
          />
          <StyledVBox>
            <p>
              Since the 1990s thousands of families have chosen Best
              Developments to provide them with their biggest investment, a
              home. With the vision of combining affordability and luxury Best
              Developments has won several awards for their outstanding service
              and homes.
            </p>
            <p>
              Our vision at Best Developments is to ensure that the house we
              build can be defined and molded to a home, by allowing a wide
              array of customizations. We provide all types of units from
              singles, townhomes, bungalows, and condos all over and around the
              GTA.
            </p>
            <p>
              Being a family business, we understand the importance of a home
              for not only basic needs but to create a strong culture and
              community. Once you buy a home from us, you’re not only a customer
              but you become a part of our ever-growing family. Contact us if
              you want to learn more and become a part of Best Developments.
            </p>
          </StyledVBox>
        </VBox>
        <H1>Say hello to our team!</H1>
        <HBox style={{ scale: "0.9" }}>
          <Community
            Name="Mohammad Iqbal"
            Description="Founder & Chairman"
            disabled
          />
          <Community Name="Kamran Iqbal" Description="President" disabled />
          <Community Name="Warris Kamran" Description="Director" disabled />
{/*           <Community
            Name="Ahsan Ashraf"
            Description="Chief Executive Officer"
            disabled
          /> */}
        </HBox>
      </VBox>
    </Section>
  );
};

export default AboutUs;
